import {NgModule} from '@angular/core';
import {ConfigModule} from '@spartacus/core';
import {IconConfig, IconResourceType} from '@spartacus/storefront';

export enum KurzIconType {
  ACTIVE = 'ACTIVE',
  AMEX = 'AMEX',
  ARROW_DOWN = 'ARROW_DOWN',
  ARROW_DOWN_CIRCLE = 'ARROW_DOWN_CIRCLE',
  ARROW_LEFT = 'ARROW_LEFT',
  ARROW_LEFT_CIRCLE = 'ARROW_LEFT_CIRCLE',
  ARROW_RIGHT = 'ARROW_RIGHT',
  ARROW_RIGHT_CIRCLE = 'ARROW_RIGHT_CIRCLE',
  ARROW_UP = 'ARROW_UP',
  ARROW_UP_CIRCLE = 'ARROW_UP_CIRCLE',
  BARS = 'BARS',
  BRUSH = 'BRUSH',
  CALENDAR = 'CALENDAR',
  CANCEL = 'CANCEL',
  CARET_DOWN = 'CARET_DOWN',
  CARET_LEFT = 'CARET_LEFT',
  CARET_RIGHT = 'CARET_RIGHT',
  CARET_UP = 'CARET_UP',
  CART = 'CART',
  CERTIFICATION = 'CERTIFICATION',
  CHECK_CIRCLE = 'CHECK_CIRCLE',
  CIRCLE = 'CIRCLE',
  CLOCK = 'CLOCK',
  CLOSE = 'CLOSE',
  CLOUD_OFF = 'CLOUD_OFF',
  COLD = 'COLD',
  COLLAPSE = 'COLLAPSE',
  COMMENT = 'COMMENT',
  COMPARE_ARROWS = 'COMPARE_ARROWS',
  CONTRACTS = 'CONTRACTS',
  CONTRACTS2 = 'CONTRACTS2',
  COPY = 'COPY',
  CREDIT_CARD = 'CREDIT_CARD',
  CURRENCY = 'CURRENCY',
  CUT = 'CUT',
  DELETE = 'DELETE',
  DINERS_CLUB = 'DINERS_CLUB',
  DOWNLOAD = 'DOWNLOAD',
  EDIT = 'EDIT',
  EMPTY_HEART = 'EMPTY_HEART',
  ENVELOPE = 'ENVELOPE',
  ERROR = 'ERROR',
  EXPAND = 'EXPAND',
  EXPAND_ARROWS = 'EXPAND_ARROWS',
  EXTERNAL = 'EXTERNAL',
  FAILURE = 'FAILURE',
  FILE = 'FILE',
  FILTER = 'FILTER',
  FILTER_OFF = 'FILTER_OFF',
  FIRST_PAGE = 'FIRST_PAGE',
  FLAME= 'FLAME',
  FULLSCREEN = 'FULLSCREEN',
  FULLSCREEN_EXIT = 'FULLSCREEN_EXIT',
  GLOBE = 'GLOBE',
  GRID = 'GRID',
  HEART = 'HEART',
  HELP = 'HELP',
  HOME = 'HOME',
  HOT = 'HOT',
  INFO = 'INFO',
  KEYBOARD_ARROW_DOWN = 'KEYBOARD_ARROW_DOWN',
  KEYBOARD_ARROW_LEFT = 'KEYBOARD_ARROW_LEFT',
  KEYBOARD_ARROW_RIGHT = 'KEYBOARD_ARROW_RIGHT',
  KEYBOARD_ARROW_UP = 'KEYBOARD_ARROW_UP',
  LAST_PAGE = 'LAST_PAGE',
  LINK_OUT = 'LINK_OUT',
  LIST = 'LIST',
  LOGIN = 'LOGIN',
  LOGOUT = 'LOGOUT',
  MASTER_CARD = 'MASTER_CARD',
  MINUS_CIRCLE = 'MINUS_CIRCLE',
  MY_DETAILS = 'MY_DETAILS',
  NEW_ORDER = 'NEW_ORDER',
  NONE = '',
  NOT_INTERESTED = 'NOT_INTERESTED',
  OFF = 'OFF',
  ON = 'ON',
  ORDERS = 'ORDERS',
  ORDER_STATUS = 'ORDER_STATUS',
  PACKAGE = 'PACKAGE',
  PASTE = 'PASTE',
  PDF = 'PDF',
  PENCIL = 'PENCIL',
  PHONE= 'CALL',
  PLANE = 'PLANE',
  PLUS = 'PLUS',
  PLUS_CIRCLE = 'PLUS_CIRCLE',
  PRICE_LIST = 'PRICE_LIST',
  REDO = 'REDO',
  RESET = 'RESET',
  SAVE = 'SAVE',
  SAVED_CARTS = 'SAVED_CARTS',
  SEARCH = 'SEARCH',
  SHINY = 'SHINY',
  SHIP = 'SHIP',
  SORT = 'SORT',
  SORT_DOWN = 'SORT_DOWN',
  STANDARD_CUT_SIZES = 'STANDARD_CUT_SIZES',
  STAR = 'STAR',
  STOCK = 'STOCK',
  STOCK_SALE = 'STOCK_SALE',
  STORE = 'STORE',
  SUCCESS = 'SUCCESS',
  THUMBS_DOWN = 'THUMBS_DOWN',
  THUMBS_UP = 'THUMBS_UP',
  TRASH = 'TRASH',
  TRUCK = 'TRUCK',
  TUNE= 'TUNE',
  UNDO = 'UNDO',
  USER = 'USER',
  VERIFIED = 'VERIFIED',
  VIDEO_TUTORIALS = 'VIDEO_TUTORIALS',
  VISA = 'VISA',
  WARNING = 'WARNING',
}

// Icons, which are rendered with the IconComponent, recognize the classes
// "scale-up-n", "scale-down-n", "pos-right-n", "pos-left-n", "pos-top-n", "pos-bottom-n"
// where n is a number in [10, 20, ... 100]

const customIconConfig: IconConfig = {
  icon: {
    symbols: {
      ACTIVE: 'material-icons material-symbols-outlined mat-icon-active',
      AMEX: 'material-icons material-symbols-outlined mat-icon-amex',
      ARROW_DOWN: 'material-icons material-symbols-outlined mat-icon-arrow-down',
      ARROW_DOWN_CIRCLE: 'material-icons material-symbols-outlined mat-icon-arrow-down-circle',
      ARROW_LEFT: 'material-icons material-symbols-outlined mat-icon-arrow-left',
      ARROW_LEFT_CIRCLE: 'material-icons material-symbols-outlined mat-icon-arrow-left-circle',
      ARROW_RIGHT: 'material-icons material-symbols-outlined mat-icon-arrow-right',
      ARROW_RIGHT_CIRCLE: 'material-icons material-symbols-outlined mat-icon-arrow-right-circle',
      ARROW_UP: 'material-icons material-symbols-outlined mat-icon-arrow-up',
      ARROW_UP_CIRCLE: 'material-icons material-symbols-outlined mat-icon-arrow-up-circle',
      BARS: 'material-icons material-symbols-outlined mat-icon-bars',
      BRUSH: 'material-icons material-symbols-outlined mat-icon-brush',
      CALENDAR: 'material-icons material-symbols-outlined mat-icon-calendar',
      CALL: 'material-icons material-symbols-outlined mat-icon-call',
      CANCEL: 'material-icons material-symbols-outlined mat-icon-cancel',
      CARET_DOWN: 'material-icons material-symbols-outlined mat-icon-caret-down scale-up-40',
      CARET_LEFT: 'material-icons material-symbols-outlined mat-icon-caret-left scale-up-40',
      CARET_RIGHT: 'material-icons material-symbols-outlined mat-icon-caret-right scale-up-40',
      CARET_UP: 'material-icons material-symbols-outlined mat-icon-caret-up scale-up-40',
      CART: 'material-icons material-symbols-outlined mat-icon-cart',
      CERTIFICATION: 'material-icons material-symbols-outlined mat-icon-certification',
      CHECK_CIRCLE: 'material-icons material-symbols-outlined mat-icon-check-circle',
      CIRCLE: 'material-icons material-symbols-outlined mat-icon-circle',
      CLOCK: 'material-icons material-symbols-outlined mat-icon-clock',
      CLOSE: 'material-icons material-symbols-outlined mat-icon-close',
      CLOUD_OFF: 'material-icons material-symbols-outlined mat-icon-cloud-off',
      COLD: 'material-icons material-symbols-outlined mat-icon-cold',
      COLLAPSE: 'material-icons material-symbols-outlined mat-icon-collapse',
      COMMENT: 'material-icons material-symbols-outlined mat-icon-comment',
      COMPARE_ARROWS: 'material-icons material-symbols-outlined mat-icon-compare-arrows',
      CONTRACTS2: 'material-icons material-symbols-outlined mat-icon-contracts2',
      CONTRACTS: 'material-icons material-symbols-outlined mat-icon-contracts',
      COPY: 'material-icons material-symbols-outlined mat-icon-copy',
      CREDIT_CARD: 'material-icons material-symbols-outlined mat-icon-credit-card',
      CURRENCY: 'material-icons material-symbols-outlined mat-icon-currency',
      CUT: 'material-icons material-symbols-outlined mat-icon-cut',
      DELETE: 'material-icons material-symbols-outlined mat-icon-delete',
      DINERS_CLUB: 'material-icons material-symbols-outlined mat-icon-diners-club',
      DOWNLOAD: 'material-icons material-symbols-outlined mat-icon-download',
      EDIT: 'material-icons material-symbols-outlined mat-icon-edit',
      EMPTY_HEART: 'material-icons material-symbols-outlined mat-icon-empty-heart',
      ENVELOPE: 'material-icons material-symbols-outlined mat-icon-envelope',
      ERROR: 'material-icons material-symbols-outlined mat-icon-error',
      EXPAND: 'material-icons material-symbols-outlined mat-icon-expand',
      EXPAND_ARROWS: 'material-icons material-symbols-outlined mat-icon-expand-arrows',
      EXTERNAL: 'material-icons material-symbols-outlined mat-icon-external',
      FAILURE: 'material-icons material-symbols-outlined mat-icon-failure',
      FILE: 'material-icons material-symbols-outlined mat-icon-file',
      FILTER: 'material-icons material-symbols-outlined mat-icon-filter',
      FILTER_OFF: 'material-icons material-symbols-outlined mat-icon-filter-off',
      FIRST_PAGE: 'material-icons material-symbols-outlined mat-icon-first-page',
      FLAME: 'material-icons material-symbols-outlined mat-icon-flame',
      FULLSCREEN: 'material-icons material-symbols-outlined mat-icon-fullscreen',
      FULLSCREEN_EXIT: 'material-icons material-symbols-outlined mat-icon-fullscreen-exit',
      GLOBE: 'material-icons material-symbols-outlined mat-icon-globe',
      GRID: 'material-icons material-symbols-outlined mat-icon-grid',
      HEART: 'material-icons material-symbols-outlined mat-icon-heart',
      HELP: 'material-icons material-symbols-outlined mat-icon-help',
      HOME: 'material-icons material-symbols-outlined mat-icon-home',
      HOT: 'material-icons material-symbols-outlined mat-icon-hot',
      INFO: 'material-icons material-symbols-outlined mat-icon-info',
      KEYBOARD_ARROW_DOWN: 'material-icons material-symbols-outlined mat-icon-keyboard-arrow-down',
      KEYBOARD_ARROW_LEFT: 'material-icons material-symbols-outlined mat-icon-keyboard-arrow-left',
      KEYBOARD_ARROW_RIGHT: 'material-icons material-symbols-outlined mat-icon-keyboard-arrow-right',
      KEYBOARD_ARROW_UP: 'material-icons material-symbols-outlined mat-icon-keyboard-arrow-up',
      LAST_PAGE: 'material-icons material-symbols-outlined mat-icon-last-page',
      LINK_OUT: 'material-icons material-symbols-outlined mat-icon-link-out',
      LIST: 'material-icons material-symbols-outlined mat-icon-list',
      LOGIN: 'material-icons material-symbols-outlined mat-icon-login',
      LOGOUT: 'material-icons material-symbols-outlined mat-icon-logout',
      MASTER_CARD: 'material-icons material-symbols-outlined mat-icon-master-card',
      MINUS_CIRCLE: 'material-icons material-symbols-outlined mat-icon-minus-circle',
      MY_DETAILS: 'material-icons material-symbols-outlined mat-icon-my-details',
      NEW_ORDER: 'kurz-ico-rel k-ico-r-14_cart_add_to pos-left-20 pos-bottom-10',
      NOT_INTERESTED: 'material-icons material-symbols-outlined mat-icon-not-interested',
      OFF: 'material-icons material-symbols-outlined mat-icon-off',
      ON: 'material-icons material-symbols-outlined mat-icon-on',
      ORDERS: 'material-icons material-symbols-outlined mat-icon-orders',
      ORDER_STATUS: 'kurz-ico-rel k-ico-r-04_openorders',
      PACKAGE: 'material-icons material-symbols-outlined mat-icon-package',
      PASTE: 'material-icons material-symbols-outlined mat-icon-paste',
      PDF: 'material-icons material-symbols-outlined mat-icon-pdf',
      PENCIL: 'material-icons material-symbols-outlined mat-icon-pencil',
      PLANE: 'material-icons material-symbols-outlined mat-icon-plane',
      PLUS: 'kurz-ico k-ico-29_inputfield_plus',
      PLUS_CIRCLE: 'material-icons material-symbols-outlined mat-icon-plus-circle',
      PRICE_LIST: 'material-icons material-symbols-outlined mat-icon-price-list',
      REDO: 'material-icons material-symbols-outlined mat-icon-redo',
      RESET: 'material-icons material-symbols-outlined mat-icon-reset',
      SAVE: 'material-icons material-symbols-outlined mat-icon-save',
      SAVED_CARTS: 'material-icons material-symbols-outlined mat-icon-saved-carts',
      SEARCH: 'material-icons material-symbols-outlined mat-icon-search',
      SHINY: 'material-icons material-symbols-outlined mat-icon-shiny',
      SHIP: 'material-icons material-symbols-outlined mat-icon-ship',
      SORT: 'material-icons material-symbols-outlined mat-icon-sort',
      SORT_DOWN: 'material-icons material-symbols-outlined mat-icon-sort-down',
      STANDARD_CUT_SIZES: 'material-icons material-symbols-outlined mat-icon-standard-cut-sizes',
      STAR: 'material-icons material-symbols-outlined mat-icon-star',
      STOCK: 'material-icons material-symbols-outlined mat-icon-stock',
      STOCK_SALE: 'kurz-ico-rel k-ico-r-61_stocksale scale-up-30 pos-left-20',
      STORE: 'material-icons material-symbols-outlined mat-icon-store',
      SUCCESS: 'material-icons material-symbols-outlined mat-icon-success',
      THUMBS_DOWN: 'material-icons material-symbols-outlined mat-icon-thumbs-down',
      THUMBS_UP: 'material-icons material-symbols-outlined mat-icon-thumbs-up',
      TRASH: 'material-icons material-symbols-outlined mat-icon-trash',
      TRUCK: 'material-icons material-symbols-outlined mat-icon-truck',
      TUNE: 'material-icons material-symbols-outlined mat-icon-tune',
      UNDO: 'material-icons material-symbols-outlined mat-icon-undo',
      USER: 'material-icons material-symbols-outlined mat-icon-user',
      VERIFIED: 'material-icons material-symbols-outlined mat-icon-verified',
      VIDEO_TUTORIALS: 'material-icons material-symbols-outlined mat-icon-video-tutorials',
      VISA: 'material-icons material-symbols-outlined mat-icon-visa',
      WARNING: 'material-icons material-symbols-outlined mat-icon-warning',
    },
    resources: [
      {
        type: IconResourceType.LINK,
        url: './assets/font-icons.css',
        types: ['NEW_ORDER', 'ORDER_STATUS', 'PLUS', 'STOCK_SALE']
      },
      // class needed "material-symbols-outlined"mat-icon-
      // see: src\styles\last\google-material-font-icon-classes.scss
      {
        type: IconResourceType.LINK,
        url: './assets/google-material-font-icons.css'
      },
      // {
      //   type: IconResourceType.LINK,
      //   url: 'https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200'
      // },
      // class needed: "material-icons"
      // {
      //   type: IconResourceType.LINK,
      //   url: 'https://fonts.googleapis.com/icon?family=Material+Icons',
      //   types: ['verified']
      // },
      // {
      //   type: IconResourceType.LINK,
      //   url: 'https://use.fontawesome.com/releases/v5.8.1/css/all.css',
      // },
    ]
  }
};

@NgModule({
  declarations: [],
  imports: [
    ConfigModule.withConfig(customIconConfig)
  ],
  exports: [
  ]
})
export class CustomIconTypesMappingModule { }
